<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>
    </div>

    <div class="box-body">
      <transition name="fade">
        <form @keydown="form.onKeydown($event)" @submit.prevent="addAdsCars">
          <div class="form-group">
            <div class="row">
              <label class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-year_from">
                <input class="form-control"
                       id="filter-by-year_from"
                       name="year_from"
                       placeholder="Год выпуска, от"
                       title="Год выпуска, от"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.year_from">
              </label>

              <label class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-year_to">
                <input class="form-control"
                       id="filter-by-year_to"
                       name="year_to"
                       placeholder="Год выпуска, до"
                       title="Год выпуска, до"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.year_to">
              </label>

              <label class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-price_from">
                <input class="form-control"
                       id="filter-by-price_from"
                       name="price_from"
                       placeholder="Цена от, $"
                       title="Цена от, $"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.price_from">
              </label>

              <label class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-price_to">
                <input class="form-control"
                       id="filter-by-price_to"
                       name="price_to"
                       placeholder="Цена до, $"
                       title="Цена до, $"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.price_to">
              </label>

              <label class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-ad_source">
                <select class="form-control"
                        id="filter-by-ad_source"
                        name="ad_source"
                        title="Площадка объявлений"
                        v-on:change="updateFilters()"
                        v-model="filter_ads_cars.ad_source">
                  <option :value="null">Площадка объявлений</option>
                  <option value="AutoRIA">AutoRIA</option>
                  <option value="OLX">OLX</option>
                </select>
              </label>


              <div class="col-xl-2 col-md-3 col-sm-6 col-xs-6 mb-1">
                <input class="pointer margin-l-5"
                       id="filter-by-with_dealers"
                       type="checkbox"
                       @input="() => {
                         filter_ads_cars.without_dealers = null;
                         filter_ads_cars.with_dealers = filter_ads_cars.with_dealers !== true;
                         updateFilters();
                       }"
                       v-model="filter_ads_cars.with_dealers">
                <label for="filter-by-with_dealers" class="font-small margin-l-5 padding-l-10">
                  Только дилеры
                </label>

                <br/>

                <input class="pointer margin-l-5"
                       id="filter-by-without_dealers"
                       type="checkbox"
                       @input="() => {
                         filter_ads_cars.with_dealers = null;
                         filter_ads_cars.without_dealers = filter_ads_cars.without_dealers !== true;
                         updateFilters();
                       }"
                       v-model="filter_ads_cars.without_dealers">
                <label for="filter-by-without_dealers" class="font-small margin-l-5 padding-l-10">
                  Без дилеров
                </label>
              </div>
            </div>

            <div class="row">
              <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
                <multiselect :allow-empty="true"
                             :options="cities"
                             :searchable="true"
                             id="filter-by-city"
                             @input="() => {
                               filter_ads_cars.city_id = city ? city.id : null;
                               updateFilters();
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Город"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Город"
                             track-by="id"
                             v-model="city">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
              </div>

              <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
                <multiselect :allow-empty="true"
                             :options="marks"
                             :searchable="true"
                             id="filter-by-mark"
                             @input="() => {
                               filter_ads_cars.mark_id = mark ? mark.id : null;
                               updateFilters();
                             }"
                             @select="getCarModels"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Марка"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Марка"
                             track-by="id"
                             v-model="mark">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
              </div>

              <div class="col-xl-2 col-md-6 col-sm-12 mb-1">
                <multiselect :allow-empty="true"
                             :disabled="!models || models.length === 0"
                             :options="models"
                             :searchable="true"
                             id="filter-by-model"
                             @input="() => {
                               filter_ads_cars.model_id = model ? model.id : null;
                               updateFilters();
                             }"
                             deselect-label="Убрать"
                             label="name"
                             placeholder="Модель"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Модель"
                             track-by="id"
                             v-model="model">
                  <template slot="placeholder">
                    <i class="fas font-scale fa-circle-notch fa-spin text-blue" v-if="onProgressCarModels === true"/>
                    Модель
                  </template>
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
              </div>

              <label class="col-xl-2 col-md-6 col-sm-12 mb-1" for="filter-by-type">
                <multiselect :allow-empty="true"
                             :options="types"
                             :searchable="true"
                             id="filter-by-type"
                             @input="() => {
                               filter_ads_cars.type_id = type ? type.id : null;
                               updateFilters();
                             }"
                             deselect-label="Убрать"
                             label="title"
                             placeholder="Тип кузова"
                             select-label="Выбрать"
                             selected-label="Выбрано"
                             title="Тип кузова"
                             track-by="id"
                             v-model="type">
                  <template slot="noResult">Не найдено</template>
                  <template slot="noOptions">Данные отсутствуют</template>
                </multiselect>
              </label>

              <label class="col-xl-1 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-race_from">
                <input class="form-control"
                       id="filter-by-race_from"
                       name="race_from"
                       placeholder="Пробег, от"
                       title="Пробег, от"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.race_from">
              </label>

              <label class="col-xl-1 col-md-3 col-sm-6 col-xs-6 mb-1" for="filter-by-race_to">
                <input class="form-control"
                       id="filter-by-race_to"
                       name="race_to"
                       placeholder="Пробег, до"
                       title="Пробег, до"
                       type="number"
                       v-on:change="updateFilters()"
                       v-model="filter_ads_cars.race_to">
              </label>

              <label class="col-xl-2 col-md-6 col-sm-12 mb-1">
                <Button :disabled="!validateData || form.busy"
                        :form="form"
                        :loading="form.busy"
                        title="Добавить новые объявления"
                        class="btn btn-success"
                        style="min-width: 60px;">
                  <i class="fa fa-plus"></i>
                </Button>
              </label>
            </div>
          </div>
        </form>
      </transition>

      <table-items :data_search="data_search"
                   :filters_query="filter_ads_cars"
                   :relations="relations"
                   component="ad-car-item"
                   :columns="columns"
                   api_resource="/ads_cars">
      </table-items>
    </div>
  </div>

</template>

<script>
import Vue from 'vue';
import TableItems from './../../components/pagination/table-items';
import Form from "vform";
import Multiselect from 'vue-multiselect';

Form.axios = API.apiClient;

Vue.component('ad-car-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'clients',

  components: {
    TableItems,
    Button, HasError, AlertError, AlertErrors,
    Multiselect,
  },

  props: {
    data_search: {
      type: String,
      required: false
    }
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'База объявлений',

    columns: [
      {
        title: 'ID',
        class: null,
        style: null,
      },
      {
        title: 'Дата',
        class: null,
        style: null,
      },
      {
        title: 'Город',
        class: null,
        style: null,
      },
      {
        title: 'Модель',
        class: null,
        style: null,
      },
      {
        title: 'Марка',
        class: null,
        style: null,
      },
      {
        title: 'Год',
        class: null,
        style: null,
      },
      {
        title: 'Цена',
        class: null,
        style: null,
      },
      {
        title: 'Имя',
        class: null,
        style: null,
      },
      {
        title: 'Телефон',
        class: null,
        style: null,
      },
      {
        title: 'Объявлений',
        class: null,
        style: null,
      },
      {
        title: 'Площадка',
        class: null,
        style: null,
      },
      {
        title: 'Ссылка',
        class: null,
        style: null,
      },
      {
        title: 'Добавлено',
        class: null,
        style: null,
      },
      {
        title: null,
        class: null,
        style: null,
      },
    ],
    relations: [
      'mark',
      'model',
      'city',
      'client',
    ],

    mark: null,
    marks: [],
    model: null,
    models: [],
    city: null,
    cities: [],
    type: null,
    types: [],

    form: new Form({
      city_id: null,
      model_id: null,
      mark_id: null,
      year_from: null,
      year_to: null,
      price_from: null,
      price_to: null,
      type_id: null,
      race_from: null,
      race_to: null,
    }),

    filter_ads_cars: {
      city_id: null,
      model_id: null,
      mark_id: null,
      year_from: null,
      year_to: null,
      price_from: null,
      price_to: null,
      type_id: null,
      race_from: null,
      race_to: null,
      ad_source: null,
      with_dealers: null,
      without_dealers: null,
    },

    onProgressCarModels: false,
  }),

  async created() {
    try {
      const [marks_response, cities_response, types_response] = await axios.all([
        API.apiClient.get('/cars/marks', {
          params: {
            is_paginated: 0,
            sorting: 'asc',
            sort_column: 'name',
          }
        }),
        API.apiClient.get('/cities', {
          params: this.$apiRequestParams.filters_list
        }),
        API.apiClient.get('/cars/types', {
          params: this.$apiRequestParams.filters_list
        }),
      ]);

      this.marks = marks_response.data;
      this.cities = cities_response.data;
      this.types = types_response.data;
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function () {
      return this.filter_ads_cars.mark_id || this.filter_ads_cars.model_id || this.filter_ads_cars.city_id;
    },
  },

  methods: {
    async getCarModels(mark) {
      try {
        this.onProgressCarModels = true;

        this.filter_ads_cars.model_id = null;
        this.models = [];
        this.model = null;

        const models_response = await API.apiClient.get(
            '/cars/mark/' + mark.id + '/models',
            {
              params: {
                is_paginated: 0,
                sorting: 'asc',
                sort_column: 'name',
              }
            }
        );

        this.models = models_response.data;
      } catch (error) {
        errorHandler(error);
      }

      this.onProgressCarModels = false;
    },

    updateFilters() {
      this.EventBus.$emit('table-filter-changed');
    },

    async addAdsCars() {
      this.form.city_id = this.filter_ads_cars.city_id;
      this.form.model_id = this.filter_ads_cars.model_id;
      this.form.mark_id = this.filter_ads_cars.mark_id;
      this.form.year_from = this.filter_ads_cars.year_from;
      this.form.year_to = this.filter_ads_cars.year_to;
      this.form.price_from = this.filter_ads_cars.price_from;
      this.form.price_to = this.filter_ads_cars.price_to;
      this.form.type_id = this.filter_ads_cars.type_id;
      this.form.race_from = this.filter_ads_cars.race_from;
      this.form.race_to = this.filter_ads_cars.race_to;

      showInfo('Объявления в процессе добавления. Когда все объявления будут добавлены, Вам придёт уведомление');

      await this.form.post('/ads_cars/add').then(() => {
        showSuccess();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }).catch((error) => {
        errorHandler(error);
      });
    },
  },
}
</script>

<style scoped>

</style>
